<template>
  <Navbar />
  <div class="container">
    <router-view />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";

export default {
  name: "App",
  components: {
    Navbar,
  },
};
</script>

<style lang="stylus">

h1,h2,h3,h4,h5,h6,p
  margin 0 !important
  padding 0 !important

body
  font-family "Montserrat", sans-serif !important


.bg-main
  background #142950 !important

.pre-image
  padding 5em 0em

.kabala-list
  display grid
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
  grid-column-gap 10px

.border-black
  border-bottom: 10px solid rgba(0,0,0,0.4) !important

.splide__slide
  height 350px
  display flex
  align-items center

.splide__slide img
  width 100%
  transform scale(0.6)

div[disabled="true"]
  pointer-events: none;
  opacity: 0.7;
</style>
