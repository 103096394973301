<template>
  <div>
    <div id="alert" class="container my-3" v-show="is_voted">
      <div class="alert alert-success" role="alert">
        <h4 class="alert-heading">Köszönjük a szavazatodat!</h4>
        <p>
          Kérjük majd látogass vissza az oldalra, hogy láthasd ki nyerte meg a szavazást.
        </p>
        <hr />
        <p class="mb-0">
          A sorsolás ideje 2021. Március 1.
        </p>
      </div>
    </div>

    <h4 class="my-3 text-center">
      Szavazz a kedvenc kabaládra!
    </h4>
    <p>
      Nézd végig a lehetséges kabalafigurákat, és válaszd ki azt az egyet, amelyik szerinted a legjobban megjeleníti az egyetemi értékeket, leginkább illeszkedik az egyetem
      szellemiségéhez, és támogasd a szavazatoddal
    </p>
    <p class="mb-3">A győztes pályaművet plüssfigura formájában az ÓE ajándékbolt értékesítheti majd.</p>
    <p>
      A szavazás menete: a főoldalon válaszd ki kedvencedet, majd kattints a „Rá szavazok" gombra. Szavazatod érvényesítéséhez add meg a Neptun azonosítódat, majd az
      „Elküldés”gombra kattintva véglegesítheted a szavazatodat. Minden hallgató csak egyszer, egy kabalára adhatja le a szavazatát.
    </p>
    <p class="mt-3 font-weight-bold">Jó voksolást kívánunk!</p>
    <div class="kabala-list" :disabled="is_voted">
      <Kabala :data="kabala" v-for="(kabala, index) in kabalas" :key="index" />
    </div>
    <Modal @on-submit="on_kabala_submitted" />
  </div>
</template>

<script>
import Kabala from "@/components/Kabala.vue";
import Modal from "@/components/Modal.vue";
import axios from "axios";

export default {
  name: "Home",
  components: {
    Kabala,
    Modal,
  },
  data() {
    return {
      submitted: false,
      is_voted: false,
      kabalas: [],
    };
  },
  mounted() {
    this.get_elements();
    this.is_voted = window.localStorage.getItem("uni_voted");
  },
  methods: {
    on_kabala_submitted(neptun, nickname, vote_id) {
      let formData = new FormData();
      formData.append("neptun", neptun);
      formData.append("nickname", nickname);
      formData.append("V0t", vote_id);

      axios
        .post("https://kabalafigura.uni-obuda.hu/reg.php", formData)
        .then((res) => {
          if (res.data.success) {
            window.localStorage.setItem("uni_voted", true);
            location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    get_elements() {
      this.kabalas = [
        {
          images: [
            "./palyamunkak/GME/ÓE.png",
            "./palyamunkak/GME/YBL.png",
            "./palyamunkak/GME/AMK.png",
            "./palyamunkak/GME/BGK.png",
            "./palyamunkak/GME/KVK.png",
            "./palyamunkak/GME/NIK.png",
            "./palyamunkak/GME/RKK.png",
            "./palyamunkak/GME/KGK.png",
          ],
          id: "GME",
        },
        {
          images: [
            "./palyamunkak/CA0/AMK.png",
            "./palyamunkak/CA0/BGK.png",
            "./palyamunkak/CA0/KVK.png",
            "./palyamunkak/CA0/NIK.png",
            "./palyamunkak/CA0/ÓE.png",
            "./palyamunkak/CA0/RKK.png",
            "./palyamunkak/CA0/KGK.png",
            "./palyamunkak/CA0/YBL.png",
          ],
          id: "CA0",
        },
        {
          images: [
            "./palyamunkak/YG9/OE DINO.png",
            "./palyamunkak/YG9/Dino_pic06.png",
            "./palyamunkak/YG9/AMK.png",
            "./palyamunkak/YG9/BGK.png",
            "./palyamunkak/YG9/KVK.png",
            "./palyamunkak/YG9/NIK.png",
            "./palyamunkak/YG9/ÓE.png",
            "./palyamunkak/YG9/RKK.png",
            "./palyamunkak/YG9/KGK.png",
            "./palyamunkak/YG9/YBL.png",
          ],
          id: "YG9",
        },
        {
          images: [
            "./palyamunkak/E3E/BánkiDonát.png",
            "./palyamunkak/E3E/RejtőSándor.png",
            "./palyamunkak/E3E/AlbaRegia.png",
            "./palyamunkak/E3E/YblMiklós.png",
            "./palyamunkak/E3E/KandóKálmán.png",
            "./palyamunkak/E3E/KeletiKároly.png",
            "./palyamunkak/E3E/NeumannJános.png",
          ],
          id: "E3E",
        },
        {
          images: [
            "./palyamunkak/QAU/AMK.png",
            "./palyamunkak/QAU/BGK.png",
            "./palyamunkak/QAU/KVK.png",
            "./palyamunkak/QAU/NIK.png",
            "./palyamunkak/QAU/ÓE.png",
            "./palyamunkak/QAU/RKK.png",
            "./palyamunkak/QAU/KGK.png",
            "./palyamunkak/QAU/YBL.png",
          ],
          id: "QAU",
        },
        {
          images: [
            "./palyamunkak/RRJ/ÓE.png",
            "./palyamunkak/RRJ/OE-KABALA-3D-nezetek.png",
            "./palyamunkak/RRJ/AMK.png",
            "./palyamunkak/RRJ/BGK.png",
            "./palyamunkak/RRJ/KVK.png",
            "./palyamunkak/RRJ/NIK.png",
            "./palyamunkak/RRJ/RKK.png",
            "./palyamunkak/RRJ/KGK.png",
            "./palyamunkak/RRJ/YBL.png",
          ],
          id: "RRJ",
        },
        {
          images: [
            "./palyamunkak/X9U/AMK.png",
            "./palyamunkak/X9U/BGK.png",
            "./palyamunkak/X9U/KVK.png",
            "./palyamunkak/X9U/NIK.png",
            "./palyamunkak/X9U/RKK.png",
            "./palyamunkak/X9U/KGK.png",
            "./palyamunkak/X9U/YBL.png",
          ],
          id: "X9U",
        },
        {
          images: [
            "./palyamunkak/EQT/AMK.png",
            "./palyamunkak/EQT/BGK.png",
            "./palyamunkak/EQT/KVK.png",
            "./palyamunkak/EQT/NIK.png",
            "./palyamunkak/EQT/ÓE.png",
            "./palyamunkak/EQT/RKK.png",
            "./palyamunkak/EQT/KGK.png",
            "./palyamunkak/EQT/YBL.png",
          ],
          id: "EQT",
        },
        {
          images: [
            "./palyamunkak/FAC/AMK.png",
            "./palyamunkak/FAC/BGK.png",
            "./palyamunkak/FAC/KVK.png",
            "./palyamunkak/FAC/NIK.png",
            "./palyamunkak/FAC/ÓE.png",
            "./palyamunkak/FAC/RKK.png",
            "./palyamunkak/FAC/KGK.png",
            "./palyamunkak/FAC/YBL.png",
          ],
          id: "FAC",
        },
        {
          images: [
            "./palyamunkak/TDG/AMK.png",
            "./palyamunkak/TDG/BGK.png",
            "./palyamunkak/TDG/KVK.png",
            "./palyamunkak/TDG/NIK.png",
            "./palyamunkak/TDG/ÓE.png",
            "./palyamunkak/TDG/RKK.png",
            "./palyamunkak/TDG/KGK.png",
            "./palyamunkak/TDG/YBL.png",
          ],
          id: "TDG",
        },
        {
          images: [
            "./palyamunkak/J36/AMK.png",
            "./palyamunkak/J36/BGK.png",
            "./palyamunkak/J36/KVK.png",
            "./palyamunkak/J36/NIK.png",
            "./palyamunkak/J36/ÓE.png",
            "./palyamunkak/J36/RKK.png",
            "./palyamunkak/J36/KGK.png",
            "./palyamunkak/J36/YBL.png",
          ],
          id: "J36",
        },
        {
          images: [
            "./palyamunkak/MKV/AMK.png",
            "./palyamunkak/MKV/BGK.png",
            "./palyamunkak/MKV/KVK.png",
            "./palyamunkak/MKV/NIK.png",
            "./palyamunkak/MKV/ÓE.png",
            "./palyamunkak/MKV/RKK.png",
            "./palyamunkak/MKV/KGK.png",
            "./palyamunkak/MKV/YBL.png",
          ],
          id: "MKV",
        },
        {
          images: [
            "./palyamunkak/VFW/AMK.png",
            "./palyamunkak/VFW/BGK.png",
            "./palyamunkak/VFW/KVK.png",
            "./palyamunkak/VFW/NIK.png",
            "./palyamunkak/VFW/ÓE.png",
            "./palyamunkak/VFW/RKK.png",
            "./palyamunkak/VFW/KGK.png",
            "./palyamunkak/VFW/YBL.png",
          ],
          id: "VFW",
        },
        {
          images: [
            "./palyamunkak/UZL/1.png",
            "./palyamunkak/UZL/3.png",
            "./palyamunkak/UZL/4.png",
            "./palyamunkak/UZL/5.png",
            "./palyamunkak/UZL/6.png",
            "./palyamunkak/UZL/7.png",
            "./palyamunkak/UZL/8.png",
            "./palyamunkak/UZL/9.png",
            "./palyamunkak/UZL/10.png",
            "./palyamunkak/UZL/11.png",
            "./palyamunkak/UZL/12.png",
            "./palyamunkak/UZL/13.png",
          ],
          id: "UZL",
        },
        {
          images: [
            "./palyamunkak/N2F/AMK.png",
            "./palyamunkak/N2F/BGK.png",
            "./palyamunkak/N2F/KVK.png",
            "./palyamunkak/N2F/NIK.png",
            "./palyamunkak/N2F/ÓE.png",
            "./palyamunkak/N2F/RKK.png",
            "./palyamunkak/N2F/KGK.png",
            "./palyamunkak/N2F/YBL.png",
          ],
          id: "N2F",
        },
        {
          images: [
            "./palyamunkak/IM6/View12.png",
            "./palyamunkak/IM6/View13.png",
            "./palyamunkak/IM6/View11.png",
            "./palyamunkak/IM6/View15.png",
            "./palyamunkak/IM6/View17.png",
            "./palyamunkak/IM6/View16.png",
            "./palyamunkak/IM6/View2.png",
            "./palyamunkak/IM6/View3.png",
            "./palyamunkak/IM6/AMK.png",
            "./palyamunkak/IM6/View1.png",
            "./palyamunkak/IM6/BGK.png",
            "./palyamunkak/IM6/KVK.png",
            "./palyamunkak/IM6/View4.png",
            "./palyamunkak/IM6/View5.png",
            "./palyamunkak/IM6/View7.png",
            "./palyamunkak/IM6/NIK.png",
            "./palyamunkak/IM6/View6.png",
            "./palyamunkak/IM6/View8.png",
            "./palyamunkak/IM6/View9.png",
            "./palyamunkak/IM6/ÓE.png",
            "./palyamunkak/IM6/View27.png",
            "./palyamunkak/IM6/RKK.png",
            "./palyamunkak/IM6/KGK.png",
            "./palyamunkak/IM6/View26.png",
            "./palyamunkak/IM6/View18.png",
            "./palyamunkak/IM6/YBL.png",
            "./palyamunkak/IM6/View19.png",
            "./palyamunkak/IM6/View25.png",
            "./palyamunkak/IM6/View21.png",
            "./palyamunkak/IM6/View20.png",
            "./palyamunkak/IM6/View22.png",
          ],
          id: "IM6",
        },
        {
          images: [
            "./palyamunkak/t X/AMK.png",
            "./palyamunkak/t X/BGK.png",
            "./palyamunkak/t X/KVK.png",
            "./palyamunkak/t X/NIK.png",
            "./palyamunkak/t X/ÓE.png",
            "./palyamunkak/t X/RKK.png",
            "./palyamunkak/t X/KGK.png",
            "./palyamunkak/t X/YBL.png",
          ],
          id: "t X",
        },
        {
          images: [
            "./palyamunkak/T12/AMK.png",
            "./palyamunkak/T12/BGK.png",
            "./palyamunkak/T12/KVK.png",
            "./palyamunkak/T12/NIK.png",
            "./palyamunkak/T12/ÓE.png",
            "./palyamunkak/T12/RKK.png",
            "./palyamunkak/T12/KGK.png",
            "./palyamunkak/T12/YBL.png",
          ],
          id: "T12",
        },
        {
          images: [
            "./palyamunkak/A4G/AMK.png",
            "./palyamunkak/A4G/BGK.png",
            "./palyamunkak/A4G/KVK.png",
            "./palyamunkak/A4G/NIK.png",
            "./palyamunkak/A4G/ÓE.png",
            "./palyamunkak/A4G/RKK.png",
            "./palyamunkak/A4G/KGK.png",
            "./palyamunkak/A4G/YBL.png",
          ],
          id: "A4G",
        },
        {
          images: [
            "./palyamunkak/VYL/AMK.png",
            "./palyamunkak/VYL/BGK.png",
            "./palyamunkak/VYL/KVK.png",
            "./palyamunkak/VYL/NIK.png",
            "./palyamunkak/VYL/ÓE.png",
            "./palyamunkak/VYL/RKK.png",
            "./palyamunkak/VYL/KGK.png",
            "./palyamunkak/VYL/YBL.png",
          ],
          id: "VYL",
        },
        {
          images: [
            "./palyamunkak/Z3I/neumannjanos.png",
            "./palyamunkak/Z3I/kandokalman.png",
            "./palyamunkak/Z3I/bankidonat.png",
            "./palyamunkak/Z3I/albaregia.png",
            "./palyamunkak/Z3I/keletikaroly.png",
            "./palyamunkak/Z3I/oeszembol.png",
            "./palyamunkak/Z3I/rejtosandor.png",
          ],
          id: "Z3I",
        },
        {
          images: [
            "./palyamunkak/ORE/AMK.png",
            "./palyamunkak/ORE/BGK.png",
            "./palyamunkak/ORE/KVK.png",
            "./palyamunkak/ORE/NIK.png",
            "./palyamunkak/ORE/ÓE.png",
            "./palyamunkak/ORE/RKK.png",
            "./palyamunkak/ORE/KGK.png",
            "./palyamunkak/ORE/YBL.png",
          ],
          id: "ORE",
        },
        {
          images: [
            "./palyamunkak/VGH/Kabala_ÓE_KVK.png",
            "./palyamunkak/VGH/Kabala_ÓE_BGK.png",
            "./palyamunkak/VGH/Kabala_ÓE_AMK.png",
            "./palyamunkak/VGH/Kabala_ÓE_NIK.png",
            "./palyamunkak/VGH/Kabala_ÓE_KGK.png",
            "./palyamunkak/VGH/Kabala_ÓE_RKK.png",
            "./palyamunkak/VGH/Kabala_ÓE_YBL.png",
            "./palyamunkak/VGH/KabalaÓE.png",
          ],
          id: "VGH",
        },
        {
          images: [
            "./palyamunkak/UW4/AMK.png",
            "./palyamunkak/UW4/BGK.png",
            "./palyamunkak/UW4/KVK.png",
            "./palyamunkak/UW4/NIK.png",
            "./palyamunkak/UW4/ÓE.png",
            "./palyamunkak/UW4/RKK.png",
            "./palyamunkak/UW4/KGK.png",
            "./palyamunkak/UW4/YBL.png",
          ],
          id: "UW4",
        },
        {
          images: [
            "./palyamunkak/ECJ/AMK.png",
            "./palyamunkak/ECJ/BGK.png",
            "./palyamunkak/ECJ/KVK.png",
            "./palyamunkak/ECJ/NIK.png",
            "./palyamunkak/ECJ/ÓE.png",
            "./palyamunkak/ECJ/RKK.png",
            "./palyamunkak/ECJ/KGK.png",
          ],
          id: "ECJ",
        },
        {
          images: [
            "./palyamunkak/BYZ/AMK.png",
            "./palyamunkak/BYZ/BGK.png",
            "./palyamunkak/BYZ/KVK.png",
            "./palyamunkak/BYZ/NIK.png",
            "./palyamunkak/BYZ/ÓE.png",
            "./palyamunkak/BYZ/RKK.png",
            "./palyamunkak/BYZ/KGK.png",
            "./palyamunkak/BYZ/YBL.png",
          ],
          id: "BYZ",
        },
        {
          images: [
            "./palyamunkak/JYB/AMK.png",
            "./palyamunkak/JYB/BGK.png",
            "./palyamunkak/JYB/KVK.png",
            "./palyamunkak/JYB/NIK.png",
            "./palyamunkak/JYB/ÓE.png",
            "./palyamunkak/JYB/RKK.png",
            "./palyamunkak/JYB/KGK.png",
            "./palyamunkak/JYB/YBL.png",
          ],
          id: "JYB",
        },
      ];
    },
  },
};
</script>
