<template>
  <div class="kabala" data-aos="flip-up">
    <splide :options="options">
      <splide-slide v-for="(image, index) of data.images" :key="index">
        <img :src="image" alt="#" />
      </splide-slide>
    </splide>
    <div class="row">
      <div class="col">
        <button @click="szavazat_leadasa" class="btn btn-lg border-black btn-block w-full bg-main font-weight-bold text-white rounded-0" data-toggle="modal" data-target="#modal">
          Rá szavazok!
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
export default {
  props: ["data"],
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      options: {
        type: "loop",
        autoplay: true,
        interval: 4250,
      },
    };
  },
  methods: {
    szavazat_leadasa: function() {
      window.localStorage.setItem("AkEMf", this.data.id);
    },
  },
};
</script>

<style lang="stylus">
.kabala
    margin 0.4em 0em

button:hover
   background: green !important

.splide__slide img
   width 100%
   weight auto
</style>
