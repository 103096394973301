import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/adatkezelesi',
		component: () => import('../views/Adatkezelesi.vue'),
	},
	{
		path: '/dijak',
		component: () => import('../views/Dijak.vue'),
	},
	{
		path: '/kapcsolat',
		component: () => import('../views/Kapcsolat.vue'),
	},
	{
		path: '/nyeremenyjatek',
		component: () => import('../views/Nyeremenyjatek.vue'),
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;
