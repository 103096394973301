import { createApp } from 'vue';
import App from './App.vue';

import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import AOS from 'aos';
import 'aos/dist/aos.css';
import router from './router';

createApp(App)
	.use(router)
	.mount('#app');

// init aos
AOS.init();
