<template>
  <nav class="bg-nav-primary navbar navbar-dark navbar-expand-md">
    <div class="container">
      <a class="navbar-brand" href="/">
        <img width="200" src="logo.png" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" href="#/">Kabalák</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#/dijak">Díjak</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#/nyeremenyjatek">Nyereményjáték</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#/adatkezelesi">Adatkezelési tájékoztató</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#/kapcsolat">Kapcsolat</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style>
.bg-nav-primary {
  background: #1d294d !important;
}
nav {
  padding: 0 !important;
}

nav a {
  color: white !important;
  text-transform: uppercase;
  font-weight: bolder !important;
  font-size: 12px;
}
nav a:hover {
  color: #f9b000 !important;
}
</style>
