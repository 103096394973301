<template>
  <!-- Modal -->
  <div class="modal rounded-0 shadow-lg" id="modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title font-weight-bold">Szavazás véglegesítése</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <form>
          <div class="modal-body">
            <div class="mb-3">
              <label class="form-label">Neptun azonosító</label>
              <input v-model="neptun_code" maxlength="6" minlength="6" type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">Milyen becenevet adnál a kabalának?</label>
              <input v-model="nickname" maxlength="32" type="text" class="form-control" />
            </div>
            <div class="mb-3 form-check">
              <input v-model="is_rules_accepted" type="checkbox" class="form-check-input" />
              <label class="form-check-label"
                >Kijelentem, hogy megismertem a Kabalafigura szavazással és nyereményjátékkal kapcsolatos
                <a href="/dokumentumok/obudai_egyetem_adatkezelesi.pdf" target="_blank">adatvédelmi és adatkezelési tájékoztatót</a></label
              >
            </div>
            <div class="mb-3 form-check">
              <input v-model="is_gdpr_accepted" type="checkbox" class="form-check-input" />
              <label class="form-check-label">Kijelentem, hogy elfogadom a nyereményjátékra vonatkozó <a href="#nyeremenyjatek" target="_blank">szabályzatot</a>.</label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn bg-main text-white rounded-0" data-dismiss="modal">Mégsem</button>
            <button
              @click="on_vote_submitted($event)"
              :disabled="!is_gdpr_accepted || !is_rules_accepted || !neptun_code || neptun_code.length < 6"
              class="btn btn-success rounded-0"
            >
              Elküldés
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      neptun_code: null,
      nickname: null,
      is_rules_accepted: false,
      is_gdpr_accepted: false,
    };
  },
  methods: {
    on_vote_submitted: function(event) {
      event.preventDefault();
      const unique_vote_id = window.localStorage.getItem("AkEMf");
      if (unique_vote_id && unique_vote_id.length < 4) {
        if (this.neptun_code && this.neptun_code.length == 6) {
          const result = this.neptun_code.match(/[(a-z)(A-Z)(0-9)]{6}/g);
          if (!result) {
            alert("Úgy tűnik, nem jó a neptun kódod, biztos jól írtad be?");
          } else {
            this.$emit("on-submit", this.neptun_code, this.nickname, unique_vote_id);
          }
        } else {
          // vicces a fiu.
          alert("Egyáltalán nem megfelelő a megadott neptun kód.");
        }
      }
    },
  },
};
</script>
